<template>
  <div>
    <h2>Liste des participant.e.s</h2>

    <p class="lead pb-3">
      Indiquer l'ensemble des participants afin de calculer le prix de la réservation et de recevoir
      les tickets d'entrée nominatifs.
      <br />
    </p>

    <div
      v-for="(contact, index) in contacts"
      :key="`contact-${index}`"
      class="card mt-3"
    >
      <div class="card-body">
        <input
          type="hidden"
          :name="`entrance[contacts][${index}][entity]`"
          :value="contact.id"
        />

        <b-button
          class="float-right"
          variant="outline-danger"
          size="sm"
          @click="
            removeContactAtIndex(index);
            updatePrice();
          "
        >
          <i class="fa fa-times" />
          Supprimer
        </b-button>
        <h4 v-if="contact.firstName === '' && contact.lastName === ''">Participant.e</h4>
        <h4 v-else>{{ contact.firstName }} {{ contact.lastName }}</h4>

        <b-form-group
          id="input-group-firstName"
          class="mt-4"
          label="Prénom:"
          label-for="firstName"
          :label-cols-sm="formGroupProps.labelColsSm"
          :label-cols-lg="formGroupProps.labelColsSm"
          :content-cols-sm="formGroupProps.contentColsSm"
          :content-cols-lg="formGroupProps.contentColsLg"
          label-align="left"
        >
          <b-form-input
            id="firstName"
            v-model="contact.firstName"
            :name="`entrance[contacts][${index}][firstName]`"
            :readonly="contact.readOnly"
            placeholder=""
            required
          />
        </b-form-group>

        <b-form-group
          id="input-group-lastName"
          label="Nom de famille:"
          label-for="lastName"
          :label-cols-sm="formGroupProps.labelColsSm"
          :label-cols-lg="formGroupProps.labelColsSm"
          :content-cols-sm="formGroupProps.contentColsSm"
          :content-cols-lg="formGroupProps.contentColsLg"
          label-align="left"
        >
          <b-form-input
            id="lastName"
            v-model="contact.lastName"
            :name="`entrance[contacts][${index}][lastName]`"
            placeholder=""
            :readonly="contact.readOnly"
            required
          />
        </b-form-group>

        <b-form-group
          id="input-group-birthdate"
          label="Date de naissance:"
          label-for="birthdate"
          :label-cols-sm="formGroupProps.labelColsSm"
          :label-cols-lg="formGroupProps.labelColsSm"
          :content-cols-sm="formGroupProps.contentColsSm"
          :content-cols-lg="formGroupProps.contentColsLg"
          label-align="left"
        >
          <date-input
            v-model="contact.birthdate"
            @input="updatePrice"
          />

          <input
            id="birthdate"
            v-model="contact.birthdate"
            :name="`entrance[contacts][${index}][birthdate]`"
            type="hidden"
          />
        </b-form-group>
      </div>
    </div>

    <div class="text-center pt-4">
      <b-button
        variant="outline-primary"
        size="lg"
        @click="
          addContact();
          updatePrice();
        "
      >
        <i class="fa fa-plus" />
        Ajouter une personne
      </b-button>
    </div>

    <br />
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { mapActions, mapMutations } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import * as rules from 'vee-validate/dist/rules';
import messages from 'vee-validate/dist/locale/fr.json';
import debounce from '../../../helpers/debounce';

import { actions, mutations } from '../../../store/calendar/constants';
import DateInput from '../../common/DateInput.vue';

// import { email, required, min }  from "vee-validate/dist/rules";

// import { mapMultiRowFields } from 'vuex-map-fields';

const { mapMultiRowFields } = createHelpers({
  getterType: 'calendar/getField',
  mutationType: 'calendar/updateField',
});

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  });
});

export default {
  name: 'ConfigureEntranceForm',
  components: { DateInput },
  props: {
    purchaseId: { required: false, type: String, default: null },
    initialContacts: { required: false, type: Array, default: () => [] },
  },
  data() {
    return {
      formGroupProps: {
        labelColsSm: 4,
        labelColsLg: 3,
        contentColsSm: null,
        contentColsLg: null,
      },
    };
  },
  computed: {
    ...mapMultiRowFields(['contacts']),
    isValid() {
      const hasWrongOnes = this.contacts.some(
        (c) => !c.firstName || !c.lastName || !c.birthdate || c.birthdate.length !== 10
      );
      return !hasWrongOnes;
    },
  },
  mounted() {
    this.setContacts(this.initialContacts);
    this.updatePrice();
    this.updatePrice = debounce(this.updatePrice, 500);
  },
  methods: {
    ...mapMutations('calendar', {
      addContact: mutations.ADD_BLANK_CONTACT,
      removeContactAtIndex: mutations.REMOVE_CONTACT_AT_INDEX,
      setContacts: mutations.SET_CONTACTS,
    }),
    ...mapActions('calendar', {
      computePrice: actions.FETCH_TOTAL,
    }),
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
    },
    updatePrice() {
      if (this.contacts.length && this.purchaseId && this.isValid) {
        this.computePrice(this.purchaseId, this.contacts);
      }
    },
  },
};
</script>
