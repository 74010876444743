import routes from '../../router/api';
import { sendGetRequest, sendPostRequest } from './Hydra';

/**
 * @param {string} partnerId
 * @returns {WalletResponse}
 */
export async function getByPartner(partnerId) {
  const url = new URL(routes.wallets, window.location.origin);
  const params = new URLSearchParams();
  params.set('partner', partnerId);
  url.search = params.toString();

  const response = await sendGetRequest(url);
  return response['hydra:member'] || undefined;
}

export async function createVirtualCreditPurchase(body, locale = 'fr', XSfUserId = null) {
  const uri = new URL(routes.virtualCreditPurchases.get, window.location.origin);
  // console.dir(uri, body, locale);
  const response = await sendPostRequest(uri, body, {
    Accept: 'application/ld+json',
    'Accept-Language': locale,
    ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
  });

  return response;
}
