<template>
  <div
    class="card rounded-xl activitycard text-light"
    :style="{ backgroundColor: wallet.product.color }"
  >
    <div class="card-body">
      <div
        class="d-flex justify-content-center align-items-center rounded-pill"
        style="width: 3em; height: 3em; background-color: #ffffff55"
      >
        <i
          class="far fa-xl"
          :class="`fa-${wallet.product.icon}`"
        />
      </div>

      <div class="mt-4 mb-3">
        <h3 class="h5">
          <span class="d-block fs-6 mb-1">
            {{ intlCurrencyFormatter.format(wallet.balance.amount) }}
          </span>
          <a
            class="text-light"
            :href="route"
          >
            {{ wallet.product.internal_name }}
          </a>
        </h3>
      </div>

      <a
        v-if="showButton"
        :href="route"
        class="btn btn-light rounded-pill font-weight-normal"
      >
        <i class="far fa-plus" />
        {{ capitalize(trans('general.topUp')) }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CenterWallet',
  props: {
    centerSlug: { type: String, required: true },
    /** @param {import("../../types/model/Wallet").Wallet} */
    wallet: { type: Object, required: true },
  },
  computed: {
    route() {
      return Routing.generate('website.center.wallet.details', {
        _locale: this.spflocale,
        product: this.wallet.product.id,
        slug: this.centerSlug,
      });
    },
    showButton() {
      return this.wallet.product.configurations.show_public;
    },
  },
};
</script>
