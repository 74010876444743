<template>
  <div>
    <div class="d-flex justify-content-between mt-4 mb-2">
      <slot name="title" />
      <div
        v-if="count > 1"
        :class="{ 'd-sm-none': count === 2, 'd-xxl-none': count === 3 }"
        class="pr-1 fs-4"
      >
        <i
          class="far fa-arrow-left cursor-pointer"
          @click="$refs.myVueperSlides.previous()"
        />
        <i
          class="far fa-arrow-right ml-3 cursor-pointer"
          @click="$refs.myVueperSlides.next()"
        />
      </div>
    </div>

    <vueper-slides
      ref="myVueperSlides"
      class="no-shadow activitycards"
      :visible-slides="Math.min(count, 3)"
      slide-multiple
      :gap="3"
      :infinite="false"
      :parallax="false"
      :bullets="false"
      :bullets-outside="false"
      :arrows="false"
      :fixed-height="fixedHeight"
      :dragging-distance="200"
      :breakpoints="{ 1400: { visibleSlides: 2 }, 500: { visibleSlides: 1 } }"
    >
      <slot name="default" />
    </vueper-slides>
  </div>
</template>

<script>
export default {
  name: 'SfActivitiesSlider',
  props: {
    count: {
      type: Number,
      required: false,
      default: 0,
    },
    fixedHeight: {
      type: String,
      required: false,
      default: '22.5rem',
    },
  },
};
</script>
