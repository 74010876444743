<template>
  <div>
    <b-form-input
      :id="id"
      v-model="localValue"
      v-mask="'##/##/####'"
      :name="name"
      :placeholder="placeholder"
      type="tel"
      required
      :state="validation"
    />
    <b-form-invalid-feedback :state="validation">
      Veuillez renseigner une date de naissance valide
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';

Vue.use(VueMask);

export default {
  name: 'DateInput',
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    id: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'jour/mois/année',
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['input'],
  data() {
    return {
      hasError: false,
    };
  },
  computed: {
    validation() {
      // Return null for normal render
      if (!this.value) return false;

      try {
        if (this.value.length !== 10) return false;
        const date = new Date(this.value);
        if (date === 'Invalid Date') return false;
        const today = new Date();
        return date <= today;
      } catch (error) {
        console.error(error);
      }
      return false;
    },
    localValue: {
      get() {
        if (this.value === null) return '';
        return this.value.split('-').reverse().join('/');
      },
      set(value) {
        this.$emit('input', this.transform(value));
      },
    },
  },
  methods: {
    transform(value) {
      if (value === null) return '';
      return value.split('/').reverse().join('-');
    },
  },
};
</script>
