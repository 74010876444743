<template>
  <div
    style="min-height: calc(100vh - 56px)"
    class="bg-bg"
  >
    <template v-if="purchase">
      <!-- BREADCRUMBS -->
      <booking-breadcrumbs
        v-if="product"
        :first-breadcrumb-route="firstBreadcrumbRoute"
        :product="product"
        :purchase="purchase"
      />

      <!-- /BREADCRUMBS -->

      <b-overlay
        :show="isLoading"
        fixed
      >
        <div class="d-flex flex-column bg-bg">
          <!-- BOOKING DETAILS -->
          <div v-if="product && purchase">
            <div class="container p-3">
              <booking-summary
                :purchase="purchase"
                :product="product"
              />
            </div>
          </div>
          <!-- /BOOKING DETAILS -->
        </div>

        <div class="bg-white">
          <slot />
        </div>
      </b-overlay>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import BookingSummary from '../../../components/booking/Home/BookingSummary.vue';
import BookingBreadcrumbs from '../../../components/booking/Breadcrumbs.vue';
import ToasterErrorHandler from '../../../components/mixins/ToasterErrorHandler';
import BookingMixin from '../../../components/mixins/BookingMixin';

export default {
  name: 'EntranceView',
  components: {
    BookingSummary,
    BookingBreadcrumbs,
  },
  mixins: [ToasterErrorHandler, BookingMixin],
  props: {
    bookingId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isSummaryOpen: false,
    };
  },
  computed: {
    ...mapGetters('booking', ['isPurchasePublic']),
    ...mapFields('booking', ['configuration', 'purchase', 'product', 'isLoading']),
    Routing() {
      return Routing;
    },
    allocationStatus() {
      return this.purchase?.allocation_status;
    },
    hasVeting() {
      return this.purchase.approbation_strategy !== 'none';
    },
    isGenuinlyAllowedToBeHere() {
      // Buyer or invited
      return (
        !!this.iAmTheBuyer || // I an the buyer
        !!(this.myAttribution && !['requested', 'refused'].includes(this.myAttribution.status))
      ); // I have an attribution that is not 'requested or 'declined'
    },
    isAllowedToBeHere() {
      return this.isGenuinlyAllowedToBeHere;
      //  || window.SportFinder.store.bossUser === true;
    },
    firstBreadcrumbRoute() {
      return Routing.generate('member.purchases', { _locale: this.spflocale });
    },
  },
  async created() {
    this.isLoading = true;

    try {
      await this.$store.dispatch('booking/loadPurchase', this.bookingId);
      const productId = this.purchase.product['@id'].split('/').pop();
      await Promise.allSettled([
        this.$store.dispatch('booking/loadProduct', productId),
        this.$store.dispatch('booking/loadPurchaseConfiguration', {
          id: this.bookingId,
          options: {
            resources: this.purchase.resources,
          },
        }),
      ]);
    } catch (e) {
      this.toastError(e);
      throw e;
    }
    this.isLoading = false;
  },
};
</script>
