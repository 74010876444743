<template>
  <div>
    <div
      v-if="isLoading"
      class="text-center p-5"
    >
      <i class="fa fa-loader fa-spin fa-2x" />
    </div>

    <activities-slider
      v-else-if="wallets && wallets.length"
      :count="wallets.length"
      :fixed-height="wallets && wallets.length ? '18em' : '11em'"
    >
      <template #title>
        {{ capitalize(trans('general.wallet')) }}
      </template>

      <template #default>
        <vueper-slide
          v-for="w in wallets"
          :key="w.id"
        >
          <template #content>
            <center-wallet
              :wallet="w"
              :center-slug="centerSlug"
            />
          </template>
        </vueper-slide>
      </template>
    </activities-slider>
    <slot v-else />
  </div>
</template>

<script>
import { getByPartner } from '../../services/api/Wallet';
import ToasterErrorHandler from '../mixins/ToasterErrorHandler';
import ActivitiesSlider from './ActivitiesSlider.vue';
import CenterWallet from './CenterWallet.vue';

export default {
  name: 'CenterWallets',
  components: { ActivitiesSlider, CenterWallet },
  mixins: [ToasterErrorHandler],
  props: {
    partnerId: { type: [Number, String], required: true },
    centerSlug: { type: String, required: true },
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      wallets: null,
    };
  },
  computed: {
    isLoggedIn() {
      return !!this.$store.state.userId;
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.load();
    }
  },
  methods: {
    async load() {
      this.isLoading = true;

      try {
        this.wallets = await getByPartner(this.partnerId);
      } catch (e) {
        console.error(e);
        this.toastError(e);
        this.isError = true;
      }
      this.isLoading = false;
    },
  },
};
</script>
