<template>
  <div class="p-4 pb-5 card text-center">
    <slot name="lead">
      {{ trans('emptyState.lead', { subject }) }}
    </slot>

    <slot />

    <div class="d-flex justify-content-center">
      <slot name="cta">
        <router-link
          v-slot="{ href, navigate }"
          :to="{ name: 'memberPurchasesArchive' }"
          custom
        >
          <b-button
            :href="href"
            variant="outline-dark"
            class="mt-4 align-self-center"
            @click="navigate"
          >
            {{ capitalize(trans('emptyState.cta', { subject })) }}
          </b-button>
        </router-link>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subject: { type: String, required: true },
  },
};
</script>
