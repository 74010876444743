<template>
  <div class="card mt-3">
    <div class="card-header">
      <b>Tarifs</b>
    </div>
    <div class="card-body stickablebutton__container">
      <dl class="row mb-0">
        <!-- <dt :class="dtClass">
          Concerne
        </dt>
        <dd :class="ddClass">
          {{ productLabel }}
        </dd>
        <dt :class="dtClass">
          Date
        </dt>
        <dd :class="ddClass">
          {{ dateLabel }}
        </dd>
        <dt :class="dtClass">
          Prestataire
        </dt>
        <dd :class="ddClass">
          {{ partnerLabel }}
        </dd> -->
        <slot name="content" />
        <dt :class="dtClass">Total</dt>
        <dd :class="ddClass">
          <span v-if="isLoading">
            <i class="fas fa-sync fa-spin" />
            Chargement
          </span>
          <span v-else>
            <span
              v-if="total === undefined || total === null"
              class="text-orange fs-4"
            >
              <i class="far fa-warning" />
              Formulaire incomplet
            </span>
            <span v-else>
              <money-format
                :value="total"
                :locale="spflocale"
                currency-code="EUR"
                :subunits-value="false"
                :hide-subunits="false"
              />
            </span>
          </span>
        </dd>
      </dl>

      <stickable-button>
        <slot name="action" />
      </stickable-button>
    </div>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import StickableButton from '../../common/StickableButton.vue';

const { mapFields } = createHelpers({
  getterType: 'calendar/getField',
  mutationType: 'calendar/updateField',
});

export default {
  name: 'EntranceSummary',
  components: { StickableButton },
  props: {
    productLabel: { required: false, type: String, default: 'n/a' },
    dateLabel: { required: false, type: String, default: 'n/a' },
    partnerLabel: { required: false, type: String, default: 'n/a' },
    purchaseId: { required: false, type: String, default: null },
  },
  data() {
    return {
      dtClass: 'col-12 ',
      ddClass: 'col-12 ',
    };
  },
  computed: {
    ...mapFields(['total', 'isLoading']),
  },
  mounted() {
    const toReveal = document.getElementsByClassName('revealAfterLoad');
    Array.from(toReveal).forEach((el) => {
      el.classList.remove('d-none');
    });
  },
};
</script>
