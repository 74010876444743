<template>
  <b-select
    :value="value"
    :disabled="!levels"
    :options="levels"
    :name="name"
    :required="required"
    @input="(v) => $emit('input', v)"
  />
</template>

<script>
export default {
  name: 'LanguageLevelType',
  props: {
    value: { type: String, required: false, default: undefined },
    name: { type: String, required: false, default: undefined },
    required: { type: Boolean, required: false, default: false },
  },
  emits: ['input'],
  data() {
    return {
      levels: [
        { text: this.trans('voc.language.mother_tongue', {}, 'messages'), value: 'mother_tongue' },
        { text: this.trans('voc.language.bilingual', {}, 'messages'), value: 'bilingual' },
        { text: this.trans('voc.language.good', {}, 'messages'), value: 'good' },
        { text: this.trans('voc.language.intermediate', {}, 'messages'), value: 'intermediate' },
        { text: this.trans('voc.language.beginner', {}, 'messages'), value: 'beginner' },
      ],
    };
  },
};
</script>
