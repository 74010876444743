<template>
  <div>
    <div class="d-flex justify-content-between mt-4 mb-2">
      <slot name="title" />
      <div>
        <i
          class="far fa-arrow-left cursor-pointer"
          @click="$refs.myVueperSlides.previous()"
        />
        <i
          class="far fa-arrow-right ml-2 cursor-pointer"
          @click="$refs.myVueperSlides.next()"
        />
      </div>
    </div>

    <vueper-slides
      ref="myVueperSlides"
      class="no-shadow matchcards"
      :visible-slides="3"
      slide-multiple
      :gap="3"
      :parallax="false"
      :bullets="false"
      :bullets-outside="false"
      :arrows="false"
      fixed-height="23rem"
      :dragging-distance="200"
      :breakpoints="{
        1400: { visibleSlides: 3 },
        1080: { visibleSlides: 2 },
        500: { visibleSlides: 1 },
      }"
    >
      <slot
        name="default"
        :is-mobile="isMobile"
      />
    </vueper-slides>
  </div>
</template>

<script>
export default {
  name: 'SfMatchesSlider',
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
};
</script>
