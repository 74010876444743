<template>
  <div
    class="pb-6 is-relative"
    :class="{ stickablebutton__container: selectedValue && selectedValue.value }"
  >
    <b-overlay :show="isSubmitting">
      <slot :selected-value="selectedValue" />
    </b-overlay>

    <stickable-button v-if="selectedValue && selectedValue.value">
      <b-button
        :disabled="isSubmitting"
        variant="primary"
        size="lg"
        block
        @click="handleMainAction"
      >
        {{ capitalize(trans(`general.pay`)) }}
        {{ intlCurrencyFormatter.format(Number.parseFloat(selectedValue.price)) }}
      </b-button>
    </stickable-button>
  </div>
</template>

<script>
import { createVirtualCreditPurchase } from '../../../services/api/Wallet';
import StickableButton from '../../common/StickableButton.vue';
import ToasterErrorHandler from '../../mixins/ToasterErrorHandler';

const MIN_REQUESTED_PRICE = 20;

export default {
  name: 'WalletTopupSelection',
  components: { StickableButton },
  mixins: [ToasterErrorHandler],
  props: {
    product: { type: String, required: true },
    slug: { type: String, required: true },
  },
  data() {
    return {
      selectedValue: { value: null, price: null },
      isSubmitting: false,
    };
  },
  computed: {
    route() {
      return Routing.generate('website.center.wallet.start', {
        entry: this.selectedValue.value,
        product: this.product,
        slug: this.slug,
      });
    },
  },
  methods: {
    async handleMainAction() {
      this.isSubmitting = true;
      // console.log('handleMainAction', this.product, this.selectedValue.value);
      // window.location = this.route;

      const creationBody = { product: `/api/products/${this.product}` };
      if (this.selectedValue.value === 'manual') {
        // Validate amount
        const amnt = parseInt(this.selectedValue.price, 10);
        if (amnt < MIN_REQUESTED_PRICE) {
          this.toastError(new Error(this.trans('wallet.amountTooLow')));
          this.isSubmitting = false;
          return;
        }
        creationBody.amount = amnt.toString();
      } else {
        creationBody.priceEntry = { id: this.selectedValue.value };
      }

      // We create a purchase
      try {
        const purchase = await createVirtualCreditPurchase(creationBody);

        const body = {
          attributions: purchase.attributions,
          guest_count: 0,
        };
        const intent = await this.$store.dispatch('booking/createPaymentIntent', {
          purchaseType: 'virtualcredit',
          purchaseId: purchase.id,
          body,
        });
        const intentId = intent?.stripe_payment_intent?.id;
        // console.dir(this.$router.generate({ name: 'bookingPayIntent', params: { intent: intentId } }).href);
        const r = Routing.generate('website.booking.field_rental.payment.payIntent', {
          purchase: purchase.id,
          paymentIntent: intentId,
        });
        window.location = r;
      } catch (e) {
        this.toastError(e);
      }
      this.isSubmitting = false;
    },
  },
};
</script>
