<template>
  <span>
    <i
      :class="[
        value == successValue ? successIcon : dangerIcon,
        value == successValue ? 'text-success' : 'text-danger',
      ]"
    />
    {{ label }}
  </span>
</template>

<script>
export default {
  name: 'BoolValueIcon',
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: Boolean,
      required: false,
      default: true,
    },
    successValue: {
      type: Boolean,
      required: false,
      default: true,
    },
    successIcon: {
      type: String,
      required: false,
      default: 'fas fa-check',
    },
    dangerIcon: {
      type: String,
      required: false,
      default: 'fa fa-times',
    },
  },
};
</script>
