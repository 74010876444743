<template>
  <i
    class="far fa-info-circle ml-1"
    @click="toggle"
  />
</template>

<script>
export default {
  props: {
    lead: { type: String, required: false, default: 'general.help' },
    leadRaw: { type: String, required: false, default: null },
    content: { type: String, required: false, default: null },
    contentRaw: { type: String, required: false, default: null },
  },
  computed: {
    formattedContent() {
      return {
        ...(this.lead ? { lead: this.lead } : {}),
        ...(this.leadRaw ? { leadRaw: this.leadRaw } : {}),
        ...(this.content ? { content: this.content } : {}),
        ...(this.contentRaw ? { contentRaw: this.contentRaw } : {}),
      };
    },
  },
  methods: {
    toggle() {
      this.$store.dispatch('infobox/toggle', this.formattedContent);
    },
  },
};
</script>
