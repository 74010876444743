<template>
  <div>
    <div class="d-flex justify-content-between mt-4 mb-2">
      <slot name="title" />
      <div class="arrow-container mt-n3">
        <span @click="scrollLeft">
          <i class="p-3 far fa-arrow-left"></i>
        </span>
        <span @click="scrollRight">
          <i class="p-3 far fa-arrow-right"></i>
        </span>
      </div>
    </div>

    <div class="slider-wrapper">
      <!-- Navigation Arrows -->

      <!-- Horizontal Scrollable Container -->
      <div
        ref="scrollContainer"
        class="horizontal-scroll-container"
      >
        <slot name="default" />
      </div>

      <!-- Message if there are no items -->
      <div
        v-if="count === 0"
        class="card text-center p-4"
      >
        No items available.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SfActivitiesNativeSlider',
  props: {
    count: {
      type: Number,
      required: false,
      default: 0,
    },
    fixedHeight: {
      type: String,
      required: false,
      default: '22.5rem',
    },
  },
  methods: {
    scrollLeft() {
      this.$refs.scrollContainer.scrollBy({ left: -350, behavior: 'smooth' });
    },
    scrollRight() {
      this.$refs.scrollContainer.scrollBy({ left: 350, behavior: 'smooth' });
    },
  },
};
</script>

<style scoped>
.slider-wrapper {
  position: relative; /* Allows positioning of the arrows */
}

.arrow-container {
  display: flex;
  z-index: 10; /* Ensures arrows are above other content */
}

.horizontal-scroll-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 1rem; /* Space between slides */
}

.slot-item {
  flex: 0 0 16rem;
}

.arrow-container i {
  cursor: pointer;
}
</style>
