<template>
  <div class="form-group row">
    <div class="col-sm-5">
      <language-type
        v-model="language"
        required
        :name="`${name}[language]`"
      />
    </div>
    <div class="col-sm-5">
      <language-level-type
        v-model="languageLevel"
        required
        :name="`${name}[level]`"
      />
    </div>
    <div class="col-sm-2">
      <slot />
    </div>
  </div>
</template>

<script>
import LanguageType from './LanguageType.vue';
import LanguageLevelType from './LanguageLevelType.vue';

export default {
  name: 'PreferedLanguageType',
  components: { LanguageType, LanguageLevelType },
  props: {
    values: { type: Object, required: false, default: undefined },
    name: { type: String, required: false, default: undefined },
  },
  data() {
    return {
      id: this.values.id,
      language: this.values.language,
      languageLevel: this.values.level,
    };
  },
};
</script>
