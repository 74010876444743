<template>
  <div>
    <b-form-group
      id="input-group-datepicker"
      label="Date:"
      label-for="input-3"
    >
      <b-form-input
        id="datepicker"
        v-model="date"
        name="field_rental_booking[time_picker][date]"
        class="mb-2"
        type="date"
        @input="onDateUpdate"
      />
    </b-form-group>

    <b-form-group
      id="input-group-3"
      :label="trans('market_place.booking.field_rental.hours_and_field')"
      label-for="input-3"
    >
      <span v-if="loading">
        <i class="fas fa-sync fa-spin" />
        {{ capitalize(trans('website.search.loading')) }}
      </span>
      <span
        v-else-if="availabilities.length === 0"
        class="text-danger"
      >
        <i class="fas fa-warning" />
        {{ capitalize(trans('market_place.booking.popover.no_availability')) }}
      </span>
      <span v-else>
        <b-form-select
          id="input-3"
          v-model="choice"
          :options="choices"
          :disabled="loading"
          required
          @input="onAvailabilitySelected"
        />
      </span>
    </b-form-group>

    <span v-if="debug">
      <ul>
        <li><b>Date</b>: '{{ date }}'</li>
        <li><b>Select choice</b>: '{{ choice }}'</li>
        <li><b>Resource</b>: '{{ resource.id }}' '{{ resource.name }}'</li>
        <li><b>start</b>: '{{ start }}'</li>
        <li><b>end</b>: '{{ end }}'</li>
        <li><b>startHour</b>: '{{ startHour }}'</li>
        <li><b>endHour</b>: '{{ endHour }}'</li>
      </ul>
    </span>
    <input
      type="hidden"
      name="field_rental_booking[resources]"
      :value="resource.id"
    />
    <input
      type="hidden"
      name="field_rental_booking[time_picker][start]"
      :value="startHour"
    />
    <input
      type="hidden"
      name="field_rental_booking[time_picker][end]"
      :value="endHour"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import debounce from '../../../helpers/debounce';
import { actions, getters, mutations } from '../../../store/calendar/constants';

export default {
  name: 'FieldRentalForm',
  props: {
    productId: { required: true, type: String },
    sportId: { required: true, type: [String, Number] },
    initialDate: { required: true, type: [String, Date] },
  },
  data() {
    return {
      isLoading: false,
      choice: null,
      date: null,
      availabilities: [],
      choices: [],
      resource: {
        id: null,
        name: null,
      },
      start: null,
      end: null,
      startHour: null,
      endHour: null,
      debug: false,
    };
  },
  computed: {
    ...mapGetters('booking', {
      datetime: getters.DATETIME,
      fetchedAvailabilities: getters.AVAILABILITIES,
      loading: getters.IS_LOADING,
      choiceFromStore: getters.CHOICE,
    }),
  },
  watch: {
    start(value) {
      this.startHour = this.formatDate(value, 'HH:mm');
    },
    end(value) {
      this.endHour = this.formatDate(value, 'HH:mm');
    },
    datetime(value) {
      this.date = this.formatDate(value, 'YYYY-MM-DD');
    },
    choiceFromStore(value) {
      const label = this.createChoiceLabelFromChoice(value);
      this.choice = this.choices.find((e) => e === label);
      this.onAvailabilitySelected(this.choice);
      // this.date = this.formatDate(value.from, 'YYYY-MM-DD')
      // this.start = value.start;
      // this.end = value.end;
      // this.resource = value.resource;
    },
    fetchedAvailabilities(value) {
      this.choice = '';
      this.choices = [];
      this.availabilities = [];
      this.availabilities = value;
      this.updateChoices();
    },
  },
  mounted() {
    // this.date = this.formatDate(new Date(), 'YYYY-MM-DD')
    this.onDateUpdate();
    this.onDateUpdate = debounce(this.onDateUpdate, 500);
  },
  created() {
    this.date = this.initialDate;
  },
  methods: {
    ...mapActions('booking', {
      fetchAvailabilities: actions.FETCH_AVAILABILITIES,
    }),
    ...mapMutations('booking', {
      setDateTime: mutations.SET_DATETIME,
    }),
    onAvailabilitySelected(value) {
      const availability = this.availabilities[this.choices.indexOf(value)];
      this.resource = availability.resource;
      this.start = availability.start;
      this.end = availability.end;
    },
    async onDateUpdate() {
      const fields = new URLSearchParams(window.location.search).getAll('fields[]');

      const datetime = moment(this.date).toDate();
      this.setDateTime(datetime);
      this.fetchAvailabilities({
        productId: this.productId,
        options: {
          sport: this.sportId,
          date: this.date,
          fields,
        },
      });
    },
    formatDate(value, format = 'DD/MM/YYYY HH:mm') {
      if (value) {
        return moment(value).format(format);
      }
      return value;
    },
    createChoiceLabelFromChoice(choice) {
      const start = this.formatDate(choice.start, 'HH:mm');
      const end = this.formatDate(choice.end, 'HH:mm');
      const duration = (choice.end.getTime() - choice.start.getTime()) / 1000 / 60;
      return `${choice.resource.name} de ${start} à ${end} (${duration} minutes)`;
    },
    updateChoices() {
      this.choices = this.availabilities.map((choice) => this.createChoiceLabelFromChoice(choice));
    },
    // async fetchChoices() {
    //   const response = api.getAvailabilities(
    //       this.productId,
    //       this.sportId,
    //       this.date
    //   );
    //   return response;
    // }
  },
};
</script>
