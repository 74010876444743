<template>
  <div class="card">
    <validation-observer
      ref="observer"
      v-slot="{ passes }"
    >
      <sf-modal
        id="findPlayers"
        ref="modal"
        size="lg"
        title="Rechercher & ajouter des joueurs"
        centered
        ok-title="Ajouter manuellement"
        cancel-title="Annuler"
        no-close-on-backdrop
        @ok.prevent="passes(() => handleOk())"
        @hidden="resetForm"
      >
        <form
          ref="form"
          @keyup.enter="search"
        >
          <div class="row">
            <div class="col">
              <validation-provider
                v-slot="v"
                rules="min:2|required"
                name="Prénom"
              >
                <b-form-group
                  id="firstName"
                  label="Prénom"
                  label-for="firstName"
                >
                  <b-form-input
                    id="firstName"
                    v-model="form.firstName"
                    :state="getValidationState(v)"
                    placeholder="Prénom"
                  />
                  <span>{{ v.errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="col">
              <validation-provider
                v-slot="v"
                rules="min:2|required"
                name="Nom de famille"
              >
                <b-form-group
                  id="lastName"
                  label="Nom de famille"
                  label-for="lastName"
                >
                  <b-form-input
                    id="lastName"
                    v-model="form.lastName"
                    :state="getValidationState(v)"
                    placeholder="Nom de famille"
                  />
                  <span>{{ v.errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <b-form-group
                id="phoneNumber"
                label="Numéro de téléphone"
                label-for="phoneNumber"
              >
                <b-form-input
                  id="phoneNumber"
                  v-model="form.phoneNumber"
                  placeholder="Numéro de téléphone"
                />
              </b-form-group>
            </div>
            <div class="col">
              <validation-provider
                rules="email"
                name="Email"
              >
                <b-form-group
                  id="email"
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    id="lastName"
                    v-model="form.email"
                    placeholder="Email"
                  />
                </b-form-group>
              </validation-provider>
            </div>
          </div>
          <b-form-group label="Possède un abonnement valide et en ordre?">
            <b-form-radio-group
              v-model="form.hasSubscription"
              :options="form.options"
              name="radio-inline"
            />
          </b-form-group>
        </form>

        <hr />
        <div class="text-center mb-3">
          <b-button
            variant="primary"
            @click="search"
          >
            <i class="fa fa-search" /> Rechercher
          </b-button>
        </div>

        <div>
          Utilisez la
          <b-button
            variant="outline-primary"
            size="xs"
            @click="search"
          >
            <i class="fa fa-search" /> Rechercher
          </b-button>
          pour trouver des abonnés et joueurs du centre sportif. <br />
          Vous ne trouvez le joueur que vous recherchez? Indiquez le manuellement. Si vous êtes sûr
          à 100% qu'il possède aussi un abonnement valide et en ordre, indiquez qu'il possède un
          abonnement valide pour calculer le prix de la réservation en conséquence. <br />

          <span class="text-orange">
            <i class="fa fa-warning" />
            En cas d'erreur ou de quota dépassé, la différence sera due au centre sportif. <br />
            Si ces informations sont incorrectes, vous devez le signaler au centre sportif
            immédiatement par téléphone
          </span>
        </div>

        <div v-if="loading"><i class="fas fa-spinner fa-spin" /> Recherche en cours...</div>

        <div
          v-if="searchResults[0]"
          class=""
        >
          <br />
          <b>Membres trouvés:</b>
          <ul>
            <li
              v-for="result in searchResults"
              :key="result.id"
              class="mt-1"
            >
              {{ result.firstName }} {{ result.lastName }}
              <b-button
                variant="outline-primary"
                size="xs"
                @click="addPlayerAndCloseModal(result)"
              >
                <i class="fa fa-plus" /> Ajouter
              </b-button>
            </li>
          </ul>
        </div>
      </sf-modal>
    </validation-observer>

    <div class="card-body">
      <h2>Informations concernant les joueurs</h2>

      <p class="lead">
        Indiquez l'ensemble des joueurs, abonnés et/ou invités, afin de mettre à jour le prix de la
        réservation.
        <br />
      </p>

      <ul>
        <li
          v-for="(player, index) in players"
          :key="index"
          class="mb-2"
        >
          <b>{{ player.firstName }} {{ player.lastName }}</b>
          <span v-if="player.deletable">
            -
            <b-button
              variant="outline-danger"
              size="xs"
              @click="removePlayer(index)"
            >
              <i class="fa fa-times" />
              Retirer
            </b-button>
          </span>

          <span class="d-inline-block">
            <bool-icon-value
              v-b-tooltip.hover
              :title="
                player.isVerified
                  ? `L'utilisateur est reconnu par le système`
                  : `L'utilisateur n'apparait pas dans notre base de données`
              "
              label="Vérifié"
              :value="player.isVerified"
            />
            |
            <bool-icon-value
              v-b-tooltip.hover
              :title="
                player.hasSubscription
                  ? `L'utilisateur possède un abonnement compatible avec la réservation`
                  : `L'utilisateur ne possède pas un abonnement compatible avec la réservation`
              "
              label="Abonné"
              :value="player.hasSubscription"
            />
            |
            <bool-icon-value
              v-b-tooltip.hover
              :title="
                player.quotaReached
                  ? `Le quota de l'utilisateur est atteint`
                  : `Le quota de l'utilisateur n'est pas atteint`
              "
              label="Réduction"
              :value="player.quotaReached"
              :success-value="false"
            />
          </span>
        </li>
      </ul>

      <b-button
        variant="primary"
        @click="showModal"
      >
        <i class="fa fa-plus" />
        Ajouter un joueur
      </b-button>

      <hr />

      <form
        name="collection"
        method="post"
      >
        <div
          v-for="(player, index) in players"
          :key="index"
        >
          <input
            type="hidden"
            :name="`collection[${index}][contact]`"
            :value="player.contactId"
          />
          <input
            type="hidden"
            :name="`collection[${index}][firstName]`"
            :value="player.firstName"
          />
          <input
            type="hidden"
            :name="`collection[${index}][lastName]`"
            :value="player.lastName"
          />
          <input
            type="hidden"
            :name="`collection[${index}][email]`"
            :value="player.email"
          />
          <input
            type="hidden"
            :name="`collection[${index}][phoneNumber]`"
            :value="player.phoneNumber"
          />
          <input
            type="hidden"
            :name="`collection[${index}][hasSubscription]`"
            :value="player.hasSubscription"
          />
        </div>

        <div class="text-center">
          <b-button
            variant="primary"
            size="xl"
            type="submit"
            :disabled="disableSubmitButton"
          >
            <i class="fa fa-plus" />
            Payer avec un abonnement
          </b-button>
          <div
            v-if="disableSubmitButton"
            class="mt-1"
          >
            <i>
              Veuillez indiquer les joueurs qui seront présents.
              <br />
              (Minimum {{ min }} joueurs, maximum {{ max }} joueurs)
            </i>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
// import { email, required, min }  from "vee-validate/dist/rules";
import messages from 'vee-validate/dist/locale/fr.json';
import { getAll } from '../../../services/api/Contact';
import BoolIconValue from '../../BoolIconValue.vue';

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  });
});

export default {
  name: 'ConfigurePlayersForm',
  components: { BoolIconValue },
  props: {
    ppp: { type: Array, required: true },
    min: { type: Number, required: true },
    max: { type: Number, required: true },
    partnerId: { type: [Number, String], required: true },
    centerId: { type: [Number, String], required: true },
  },
  data() {
    return {
      loading: false,
      searchResults: [],
      form: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        hasSubscription: false,
        options: [
          { text: 'Non', value: false },
          { text: 'Oui', value: true },
        ],
      },
      players: this.ppp,
    };
  },
  computed: {
    count() {
      return this.players.length;
    },
    disableSubmitButton() {
      if (this.players === undefined) return false;
      return this.players.length < this.min || this.players.length > this.max;
    },
  },
  methods: {
    showModal() {
      if (this.count >= this.max) {
        this.toast('Maximum de joueurs atteint');
        return;
      }

      this.$refs.modal.show();
    },
    handleOk() {
      // Prevent modal from closing
      this.addPlayer({
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        hasSubscription: this.form.hasSubscription,
        isVerified: false,
        quotaReached: !this.form.hasSubscription,
        contactId: null,
        deletable: true,
      });
      this.resetForm();
      this.closeModal();
    },
    resetForm() {
      this.form.firstName = '';
      this.form.lastName = '';
      this.form.email = '';
      this.form.phoneNumber = '';
      this.form.hasSubscription = false;
      this.searchResults = [];

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    search() {
      this.loading = true;

      this.searchResults = getAll({
        partner: this.partnerId,
        center: this.centerId,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        phoneNumber: this.form.phoneNumber,
        email: this.form.email,
      })
        .then((response) =>
          response.json().then((data) => {
            this.searchResults = data.data;
          })
        )
        .finally(() => {
          this.loading = false;
        });
    },
    addPlayer(player) {
      // let _players = this.players;
      if (!Object.hasOwnProperty.call(player, 'deletable')) {
        player.deletable = true;
      }
      if (!Object.hasOwnProperty.call(player, 'isVerified')) {
        player.isVerified = true;
      }
      if (!Object.hasOwnProperty.call(player, 'quotaReached')) {
        player.quotaReached = false;
      }

      if (
        this.players.some((elt) => {
          if (player.contactId && elt.contactId === player.contactId) return true;
          return (
            elt.firstName === player.firstName &&
            elt.lastName === player.lastName &&
            elt.phoneNumber === player.phoneNumber &&
            elt.email === player.email
          );
        })
      ) {
        this.toast('Doublon détecté');
        return;
      }
      this.players.push(player);
    },
    removePlayer(index) {
      this.players.splice(index, 1);
    },
    addPlayerAndCloseModal(player) {
      this.addPlayer(player);
      this.resetForm();
      this.closeModal();
    },
    closeModal() {
      this.$bvModal.hide('findPlayers');
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
